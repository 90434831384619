import React from "react"
import Layout from "../components/layout"
import Footer from "../components/footer"
import SpeakIcon from "../images/speakIcon.png"
import ReCAPTCHA from "react-google-recaptcha"
import ImageContainer from "../components/imageContainer"
import BackgroundDefault from "../components/backgroundDefault"
import addToMailchimp from "gatsby-plugin-mailchimp"
import Send from "../images/send.png"
import Email from "../images/email.png"
const Style = {
  MainSection: "shadow bg-white rounded-lg  p-4 xl:w-full sm:p-8 xl:mr-2",
  FormMainDiv:
    "flex flex-wrap justify-between w-full my-4 py-4 px-3 sm:py-6 xl:pr-12",
  InputDiv: "w-full mb-8 sm:mb-6 sm:w-full ",
  InputLabel: "w-full  px-0  text-primary text-lg  plus-bold sm:text-2xl",
  InputClass:
    "w-full rounded-lg mt-4 border-2 rounded py-2 px-3 frk-regular text-primary  outline-none focus:border-gray-500",
  InputErrorMsgText: "text-xs px-1 text-red-600",
  TextAreaDiv: "w-full mb-8 sm:mb-10",
  TextAreaLabel: "text-lg plus-bold px-0  text-primary w-full sm:text-2xl ",
  TextAreaText:
    "w-full h-64 rounded-lg mt-4 appearance-none border rounded py-2 px-3  frk-regular text-primary text-sm outline-none",
  SendInfoDiv: "w-full h-auto",
  ReCAPTCHADiv: "mb-10",
  ErrorText: "text-xs text-red-600",
  SpanClass: "text-red-600",
}

const SpanCom = ({ require }) => {
  if (require && require.length > 0)
    return <span className={Style.SpanClass}>*</span>
  else return ""
}

const InputComponent = ({
  labelName,
  type,
  requireStatus,
  value,
  onFocus,
  inputName,
  placeholder,
  onChange,
  errorStatus,
  errorMessage,
}) => {
  return (
    <div className={Style.InputDiv}>
      <label className={Style.InputLabel} htmlFor={`${inputName}_id`}>
        {labelName} <SpanCom require={errorStatus} />
      </label>
      <input
        type={type}
        className={`${Style.InputClass} ${requireStatus}`}
        id={`${inputName}_id`}
        value={value}
        onFocus={onFocus}
        placeholder={placeholder}
        name={inputName}
        onChange={onChange}
      />
      <p className={`${Style.InputErrorMsgText} ${errorStatus}`}>
        {errorMessage}
      </p>
    </div>
  )
}

class ContactUs extends React.Component {
  state = {
    success: false,
    FNAME: "",
    EMAIL: "",
    merge2: "",
    token: "",
    requireFNAME: "",
    errorFNAME: "hidden",
    requireEMAIL: "",
    errorEMAIL: "hidden",
    errorMessageEMAIL: "Please enter your email",
    requireToken: "border-none",
    errorToken: "hidden",
  }

  OnFocusInput = e => {
    this.setState({
      [`require${e.target.name}`]: "",
      [`error${e.target.name}`]: "hidden",
    })
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    })
  }

  verifyReCaptcha = value => {
    let verify = value && value.length > 0 ? value : ""
    if (value && value.length > 0 && this.state.errorToken === "block") {
      this.setState({
        token: verify,
        errorToken: "hidden",
        requireToken: "border-none",
      })
    } else {
      this.setState({ token: verify })
    }
  }

  handleSubmit = async e => {
    //
    e.preventDefault()
    let KeyValidation = true
    if (this.state.EMAIL === "") {
      KeyValidation = false
      this.setState({
        requireEMAIL: "border-red-600",
        errorEMAIL: "block",
      })
    }
    if (this.state.FNAME === "") {
      KeyValidation = false
      this.setState({
        requireFNAME: "border-red-600",
        errorFNAME: "block",
      })
    }
    if (this.state.token === "") {
      KeyValidation = false
      this.setState({
        requireToken: "border-red-600",
        errorToken: "block",
      })
    }
    if (KeyValidation === true && this.state.errorEMAIL === "hidden") {
      const result = await addToMailchimp(this.state.EMAIL, { FNAME: this.state.FNAME, EMAIL: this.state.EMAIL, merge2: this.state.merge2})
      //const result={result:"success"}
      if (result.result === "error") {
        this.setState({
          requireEMAIL: "border-red-600",
          errorEMAIL: "block",
          errorMessageEMAIL: "Invalid email",
          requireFNAME: "border-tertiary",
          errorFNAME: "hidden",
        })
      } else if (result.result === "success") {
        this.setState({
          success: true,
          requireEMAIL: "border-green-300",
          errorEMAIL: "hidden",
          errorMessageEMAIL: "",
          requireFNAME: "border-green-300",
          errorFNAME: "hidden",
        })
      }
    }
  }

  form = () => {
    return (
      <form>
        <InputComponent
          labelName="Your name"
          type="text"
          requireStatus={this.state.requireFNAME}
          value={this.state.FNAME}
          onFocus={e => this.OnFocusInput(e)}
          placeholder="Your Name Here"
          inputName="FNAME"
          onChange={e => this.handleChange(e)}
          errorStatus={this.state.errorFNAME}
          errorMessage="Please enter your name"
        />
        <InputComponent
          labelName="Email Address"
          type="email"
          requireStatus={this.state.requireEMAIL}
          value={this.state.EMAIL}
          onFocus={e => this.OnFocusInput(e)}
          placeholder="Your Email Here"
          inputName="EMAIL"
          onChange={e => this.handleChange(e)}
          errorStatus={this.state.errorEMAIL}
          errorMessage={this.state.errorMessageEMAIL}
        />
        <div className={Style.TextAreaDiv}>
          <label className={Style.TextAreaLabel} htmlFor="MMERGE4_id">
            Brief description of your issue
          </label>
          <textarea
            type="text"
            id="MMERGE4_id"
            className={Style.TextAreaText}
            name="merge2"
            onChange={e => this.handleChange(e)}
          />
        </div>
        <div className={Style.SendInfoDiv}>
          <div className={`${Style.ReCAPTCHADiv} hidden sm:block`}>
            <ReCAPTCHA
              size="normal"
              className={`border-2 ${this.state.requireToken}`}
              style={{ width: "306px" }}
              hl="en"
              sitekey={
                process.env.GATSBY_RECAPCHA_SITE_KEY
                  ? process.env.GATSBY_RECAPCHA_SITE_KEY
                  : "xxxx"
              }
              onChange={value => this.verifyReCaptcha(value)}
            />
            <p className={`${Style.ErrorText} ${this.state.errorToken}`}>
              Please verify that you are a human.
            </p>
          </div>
          <div className={`${Style.ReCAPTCHADiv} sm:hidden `}>
            <ReCAPTCHA
              size="compact"
              className={`border-2 ${this.state.requireToken} `}
              style={{ width: "162px", height: "142px" }}
              hl="en"
              sitekey={
                process.env.GATSBY_RECAPCHA_SITE_KEY
                  ? process.env.GATSBY_RECAPCHA_SITE_KEY
                  : "xxxx"
              }
              onChange={value => this.verifyReCaptcha(value)}
            />
            <p className={`${Style.ErrorText} ${this.state.errorToken}`}>
              Please verify that you are a human.
            </p>
          </div>
        </div>
        <button
          onClick={e => this.handleSubmit(e)}
          className="outline-none bg-green  w-full py-2 rounded-full plus-bold text-2xl text-white sm:w-56 sm:mb-6 focus:outline-none active:bg-teal-700"
        >
          Send
        </button>
      </form>
    )
  }

  sendSuccess = () => {
    return (
      <div className="w-full pt-20 pb-24 sm:pt-24 sm:pb-40 lg:pt-48 lg:pb-64">
        <ImageContainer container="mx-auto w-48" src={Send} alt="send.png" />
        <p className="text-successcont text-center plus-bold text-3xl sm:text-4xl mt-10 sm:mt-16">
          Thanks for getting in touch!
        </p>
      </div>
    )
  }

  render() {
    return (
      <Layout title="Contact" link="/contact-us">
        <main className="relative">
          <BackgroundDefault />
          <div className="container relative pt-24 pb-12 sm:pt-40 xl:pb-16">
            <div className="W-full text-center plus-bold text-white">
              <h2 className="text-5xl sm:mb-2 lg:text-6xl">Contact</h2>
              <p className="text-2xl sm:text-3xl lg:text-4xl">
                Need help with anything?
              </p>
            </div>
          </div>

          {/*Form Section*/}
          <section className="container relative mb-32  sm:px-16 flex flex-col xl:flex-row xl:mb-56">
            <div className={Style.MainSection}>
              {this.state.success ? this.sendSuccess()  :this.form() }
            </div>

            {/*Icon Section*/}
            <div className="container mt-12 px-0 sm:mt-24 xl:my-auto xl:w-9/12">
              <ImageContainer
                className="w-72 mx-auto px-1 sm:w-84 sm:px-0 "
                src={SpeakIcon}
                alt="SpeakIcon"
              />
              <div className="flex border w-64  mx-auto p-2   bg-lightb rounded-md mt-8">
                <ImageContainer className="h-6 w-6 mx-2 sm:mx-3" src={Email} />
                <p className="text-purple frk-regular text-sm">
                  info@babyblockstracker.com
                </p>
              </div>
            </div>
          </section>
          <Footer />
        </main>
      </Layout>
    )
  }
}

export default ContactUs
