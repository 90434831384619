import React from "react"
import ImageContainer from "../components/imageContainer"
import Cloud from "../images/cloud.png"

const BackgroundDefault = ({}) => {
  return (
    <section className className="gradientHome absolute  h-73 w-full">
      <ImageContainer
        container="w-full mt-48 z-0 sm:mt-40 lg:mt-24 "
        src={Cloud}
        alt="cloud"
      />
    </section>
  )
}

export default BackgroundDefault
